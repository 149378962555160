




















































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ZbProfileService from '@/common/services/profile/profile.service';
import { UserInfo } from '@/common/models/user';
import { UpdatePassword } from '@/common/models/profile';
import displayMessage from '@/common/functions/display-message';
import { getUserId, getIsAdmin } from '@/common/functions/auth';
import LoginStore from '@/store/modules/login';

@Component({
  name: 'Profile',
})
export default class Order extends Vue {
  LoginStore = getModule(LoginStore, this.$store);

  profileService!: ZbProfileService;

  $refs!: {
    formPassword: HTMLFormElement;
  };

  checkbox = false;

  isEditMode = false;

  userName = '';

  userId = -1;

  isAdmin = false;

  email = '';

  titleNumber = -1;

  institution = '';

  street = '';

  zipCode = '';

  city = '';

  houseNumber = '';

  enablePassReset = false;

  newPassDialog = '';

  newPassConfirmDialog = '';

  show1 = false;

  show2 = false;

  show3 = false;

  newUser = {};

  addressFormIndex = 0;

  notes = '';

  beforeMount() {
    this.isAdmin = getIsAdmin();
    const savedUserId = getUserId();

    if (this.isAdmin && (!this.$route.query.userId || this.$route.query.userId === savedUserId)) {
      this.$router.push('/users');
    }
    if (!this.$route.query.userId) {
      const savedUserId = getUserId();
      if (savedUserId) {
        this.userId = +savedUserId;
      }
      return;
    }
    this.userId = +this.$route.query.userId;
  }

  mounted() {
    this.profileService = new ZbProfileService();
    this.fillUserData();
  }

  async fillUserData() {
    const usersInfo = await this.profileService.getProfileData(this.userId);
    if (usersInfo.data) {
      this.userId = usersInfo.data.id;
      this.email = usersInfo.data.email;
      this.userName = usersInfo.data.username ?? '';
      if (usersInfo.data.profile) {
        this.institution = usersInfo.data.profile.institution ?? '';
        this.street = usersInfo.data.profile.street ?? '';
        this.zipCode = usersInfo.data.profile.zip_code ?? '';
        this.city = usersInfo.data.profile.city ?? '';
        this.houseNumber = usersInfo.data.profile.house_number ?? '';
        this.notes = usersInfo.data.profile.notes ?? '';
      } else {
        displayMessage('Keine Profilinformationen', true, this.$store);
      }
    } else {
      displayMessage(usersInfo.message, true, this.$store);
    }
  }

  openResetPassDialog() {
    this.enablePassReset = true;
  }

  closeResetPassDialog() {
    this.cleanPassReset();
    this.enablePassReset = false;
  }

  cleanPassReset() {
    this.newPassDialog = '';
    this.newPassConfirmDialog = '';
  }

  async submitProfileForm() {
    const newUser: UserInfo = {
      id: this.userId,
      email: this.email,
      profile: {
        institution: this.institution,
        street: this.street,
        zip_code: this.zipCode,
        city: this.city,
        house_number: this.houseNumber,
        notes: this.notes,
      },
    };

    const usersInfo = await this.profileService.saveProfileData(newUser);
    if (usersInfo.data) {
      this.userId = usersInfo.data.id;
      this.email = usersInfo.data.email;
      this.institution = usersInfo.data.profile.institution ?? '';
      this.street = usersInfo.data.profile.street ?? '';
      this.zipCode = usersInfo.data.profile.zip_code ?? '';
      this.city = usersInfo.data.profile.city ?? '';
      this.houseNumber = usersInfo.data.profile.house_number ?? '';
      this.notes = usersInfo.data.profile.notes ?? '';
      this.isEditMode = false;
      displayMessage(usersInfo.message, false, this.$store);
    } else {
      displayMessage(usersInfo.message, true, this.$store);
    }
  }

  async submitResetPasswordForm() {
    const updatePassword: UpdatePassword = {
      old_password: '',
      password: this.newPassDialog,
      password2: this.newPassConfirmDialog,
    };

    const confimrUpdate = await this.profileService.updateUserPassword(this.userId, updatePassword);
    if (confimrUpdate.statusCode !== 200) {
      displayMessage(confimrUpdate.message, true, this.$store);
    } else {
      displayMessage(confimrUpdate.message, false, this.$store);
      // this.$refs.formPassword.resetValidations();
      this.newPassDialog = '';
      this.newPassConfirmDialog = '';
      this.enablePassReset = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  limit(
    event: { preventDefault: () => void; key: string },
    dataProp: string | unknown[] | null,
    limit: number
  ) {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
      return;
    }

    if (dataProp && event.key !== 'Backspace') {
      if (dataProp.length >= limit) {
        event.preventDefault();
      }
    }
  }

  @Watch('$store.state.login.isAdmin')
  LoggedAdminStatus() {
    this.isAdmin = getIsAdmin();
  }
}
